body {
	margin: 10px;
	padding: 0;
}
input,
textarea,
button {
	font-family: 'Times New Roman', Times, serif;
}
p,
h1 {
	margin: 0;
	padding: 0;
}
.gray {
	color: gray;
}
pre {
	font-size: 9px;
}
button {
	margin: 2px;
}
.flex {
	display: flex;
	/* flex-direction: column; */
}
